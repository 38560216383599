import React from 'react';
import ContactForm from './components/ContactForm';

const HorlyLandingPage = () => {
  return (
    <div className="bg-[#FFFCFA] font-['Inter'] min-h-screen overflow-x-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 pb-12 md:pb-32">
        <div className="w-32 mb-12">
          <img src="/logo_horly.svg" alt="Horly Logo" className="w-full" />
        </div>

        <div className="grid md:grid-cols-2 gap-4 items-start mb-4">
          <div>
            <div className="mb-16 md:mb-8">
              <h1 className="text-4xl md:text-5xl font-bold mb-4 md:whitespace-nowrap">
                Redefining Watch Trading
              </h1>
              <p className="text-gray-600">
                Built by watch traders, for watch traders.
              </p>
            </div>

            <div className="mt-16 md:mt-0">
              <p className="text-gray-600 text-lg mb-4">Join our Waitlist!</p>
              <ContactForm />
            </div>
          </div>

          <div className="relative md:h-[300px] flex items-start justify-end mb-8 md:mb-0">
            <div className="w-full md:pl-20">
              <img
                src="/iPhone 15 Pro.png"
                alt="Trading Chart Interface"
                className="w-11/12 pl-12 md:pl-0 md:w-full max-w-sm mx-auto md:ml-auto mt-12 md:-mt-12"
              />
            </div>
          </div>
        </div>

        <div className="mt-20 md:mt-0 mb-8 md:mb-8 w-full">
          <h2 className="text-2xl font-semibold mb-9">
            Backed by
          </h2>
          <div className="relative w-full overflow-hidden">
            <div className="slider">
              <div className="slide-track">
                <div className="slide">
                  <img src="/Alliance_Light.png" alt="Alliance" className="w-44 object-contain" />
                </div>
                <div className="slide">
                  <img src="/beluga-labs.png" alt="Beluga Labs" className="w-44" />
                </div>
                <div className="slide">
                  <img src="/VAMIENT.png" alt="Vamient" className="w-44" />
                </div>
                <div className="slide">
                  <img src="/Alliance_Light.png" alt="Alliance" className="w-44 object-contain" />
                </div>
                <div className="slide">
                  <img src="/beluga-labs.png" alt="Beluga Labs" className="w-44" />
                </div>
                <div className="slide">
                  <img src="/VAMIENT.png" alt="Vamient" className="w-44" />
                </div>
                <div className="slide">
                  <img src="/Alliance_Light.png" alt="Alliance" className="w-44 object-contain" />
                </div>
                <div className="slide">
                  <img src="/beluga-labs.png" alt="Beluga Labs" className="w-44" />
                </div>
                <div className="slide">
                  <img src="/VAMIENT.png" alt="Vamient" className="w-44" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#253C30] to-[#64A282] text-white py-4 relative md:fixed bottom-0 w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-2">
            <h3 className="font-semibold mb-2">Legal</h3>
            <div className="flex gap-6">
              <a href="https://horly.gitbook.io/horly-docs" target="_blank" rel="noopener noreferrer" className="hover:opacity-80">Privacy Policy</a>
              <a href="https://horly.gitbook.io/horly-docs/general-terms-of-use" target="_blank" rel="noopener noreferrer" className="hover:opacity-80">Terms of Use</a>
            </div>
          </div>
          <div className="text-sm opacity-80">
            © 2024 Horly. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorlyLandingPage;