import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: ''
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');

    try {
      // Première tentative : création du contact
      const response = await axios({
        method: 'post',
        url: 'https://api.brevo.com/v3/contacts',
        headers: {
          'api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        data: {
          email: formData.email,
          attributes: {
            SMS: formData.phoneNumber
          },
          updateEnabled: true
        }
      });

      setStatus('success');
      setFormData({ email: '', phoneNumber: '' });
    } catch (error) {
      if (error.response?.data?.code === 'duplicate_parameter') {
        try {
          // Si le contact existe, on essaie de le mettre à jour
          const updateResponse = await axios({
            method: 'put',
            url: `https://api.brevo.com/v3/contacts/${encodeURIComponent(formData.email)}`,
            headers: {
              'api-key': process.env.REACT_APP_API_KEY,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            data: {
              attributes: {
                SMS: formData.phoneNumber
              }
            }
          });
          
          setStatus('success');
          setFormData({ email: '', phoneNumber: '' });
        } catch (updateError) {
          console.log('Update error:', updateError.response?.data);
          if (updateError.response?.data?.message?.includes('SMS is already associated')) {
            setStatus('error-phone');
          } else {
            setStatus('error');
          }
        }
      } else {
        console.log('Error:', error.response?.data);
        setStatus('error');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4 mb-4">
      <div className="flex flex-col gap-4">
        <input 
          type="email" 
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your email" 
          className="text-base md:text-sm w-full sm:w-[320px] h-14 md:h-12 px-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#64A282]" 
          required
        />
        <input 
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Phone number with country code" 
          className="text-base md:text-sm w-full sm:w-[320px] h-14 md:h-12 px-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#64A282]" 
          required
        />
        <button 
          type="submit"
          disabled={status === 'loading'}
          className="w-full sm:w-[320px] h-14 md:h-12 bg-gradient-to-r from-[#253C30] to-[#64A282] text-white text-base md:text-sm rounded-md hover:opacity-90 transition-opacity disabled:opacity-50"
        >
          {status === 'loading' ? 'Joining...' : 'Join'}
        </button>
        
        {status === 'success' && (
          <p className="text-green-600 text-sm">Successfully joined the waitlist!</p>
        )}
        {status === 'error-phone' && (
          <p className="text-red-600 text-sm">This phone number is already registered. Please use a different one.</p>
        )}
        {status === 'error' && (
          <p className="text-red-600 text-sm">An error occurred. Please try again.</p>
        )}
      </div>

      <div className="w-32 -mt-1">
        <img 
          src="/Download_on_the_App_Store_Badge.svg" 
          alt="Download on App Store" 
          className="w-full" 
        />
      </div>
    </form>
  );
};

export default ContactForm;